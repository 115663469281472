body{ background: #FFF; background-size: cover; color: #000;}

::-webkit-input-placeholder {opacity:1; color: #acacac;}
:-moz-placeholder{opacity:1; color: #acacac;}
::-moz-placeholder{opacity:1; color: #acacac;}
:-ms-input-placeholder{opacity:1; color: #acacac;} 

/* Color Variable */ 
:root {
    --primary: #21c16b;
    --secondary: #52f195;
    --body: #FFF;
    --box: #35354F80;
    --grey: #27273E;
    --light-grey: #FFFFFF29;
    --white: #FFFFFF;
    --dark-blue: #100F27;

    --gunmetal: #2E2E46;
    --crayola: #33334A;
    --charcoal: #35354F;
    --black: #000000;
}
  /* Color Variable */